@font-face {
  font-family: "Archtitect's Daughter";
  src: url("./ArchitectsDaughter-Regular.ttf");
}

html,
body,
#root,
#the-expans--app,
#the-expans--app > svg {
  height: 100%;
  width: 100%;
}

#the-expans--app {
  background: #3ea087;
  font-family: "Archtitect's Daughter";
}
